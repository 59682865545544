<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>FAQ</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ FAQ
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="faqCategory()"
                      text
                      x-large
                  >
                    FAQ category
                  </v-btn>
                  <v-btn
                      @click="createFaq()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add FAQ
                  </v-btn>

                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
               <v-row>
                 <v-col cols="12" sm="6" md="3">
                   <v-text-field
                       label="Name"
                       v-model="search.name"
                       v-on:keyup.enter="searchFaq"
                       @input="search.name = $event !== null ? $event : ''"
                       clearable
                       outlined
                       dense
                   ></v-text-field>
                 </v-col>
                 <v-col cols="12" sm="6" md="3">
                   <v-select
                       :items="categories"
                       v-model="search.category_ids"
                       v-on:keyup.enter="searchFaq"
                       @input="search.category_ids = $event !== null ? $event : ''"
                       label="Faq category"
                       item-text="name"
                       item-value="id"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>

                 <v-col cols="12" sm="6" md="3">
                   <v-select
                       :items="product_types"
                       v-model="search.product_type"
                       v-on:keyup.enter="searchFaq"
                       @input="search.product_type = $event !== null ? $event : ''"
                       label="Product type"
                       item-text="name"
                       item-value="value"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>

                 <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type == 'federal'">
                   <v-select
                       :items="scores"
                       v-model="search.score_id"
                       v-on:keyup.enter="searchFaq"
                       @input="search.score_id = $event !== null ? $event : ''"
                       label="SCORE"
                       item-text="name"
                       item-value="id"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>
                 <v-col cols="12" sm="6" md="3">
                   <v-select
                       label="Status"
                       v-model="search.is_active"
                       v-on:keyup.enter="searchFaq"
                       @input="search.is_active = $event !== null ? $event : ''"
                       :items="status"
                       item-text="name"
                       item-value="value"
                       clearable
                       outlined
                       dense
                   ></v-select>
                 </v-col>
                 <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 12 : 9" class="text-right">
                   <v-btn
                       @click.prevent="searchFaq"
                       v-on:keyup.enter="searchFaq"
                       class="btn btn-primary"
                       style="color: #fff;"
                       :loading="loading"
                   >
                     <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                     Search
                   </v-btn>
                 </v-col>
               </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" style="max-width: 400px !important; white-space: pre-wrap;">Name</th>
                  <th class="px-3">Position</th>
                  <th class="px-3">Score</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="faqs" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(faq, index) in faqs" :key="index">
                      <td class="px-3">
                        <a @click="editFaq(faq.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          <i class="fa fa-sort"></i> {{ faq.name }}
                        </a>
                        <span v-if="faq.is_product_category" class="badge"
                              v-bind:class="{ 'badge-success': faq.is_product_category, 'badge-danger': !faq.is_product_category }"
                        >{{ faq.is_product_category ? 'Product FAQ' : '' }}</span>
                        {{ faq.product_type_text ? faq.product_type_text : '' }}
                      </td>
                      <td class="px-3">
                        {{ faq.position }}
                      </td>
<!--                      <td class="px-3">-->
<!--                        <ul v-if="faq.categories">-->
<!--                          <li v-for="category in faq.categories" :key="category.id">-->
<!--                            {{ category }}-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                        <span class="font-weight-bolder text-dark-65" v-else>-->
<!--                          NA-->
<!--                        </span>-->
<!--                      </td>-->

                      <td class="px-3" style="max-width: 400px !important; white-space: pre-wrap;">
                        {{ faq.score ? faq.score.name : 'All' }}
                      </td>

                      <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': faq.is_active, 'badge-danger': !faq.is_active }"
                        >{{ faq.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editFaq(faq.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteFaq(faq.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="faqs.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No faq item</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import FaqService from "@/services/cms/faq/FaqService";
import FaqCategoryService from "@/services/cms/faqCategory/FaqCategoryService";
import ScoreService from "@/services/score/score/ScoreService";
import draggable from "vuedraggable";

const faq = new FaqService();
const faqCategory = new FaqCategoryService();
const score = new ScoreService();

export default {
  components: {
    draggable
  },
  data() {
    return{
      faqs:[],
      scores:[],
      categories:[],
      total: null,
      perPage: null,
      page: null,
      currentUser: {},
      loading: false,
      isSorting: false,
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      product_types: [
        { name: 'Online theory exam', value: 'exam' },
        { name: 'Online theory course', value: 'course' },
        { name: 'Online practical exam', value: 'practical' }
      ],
      search:{
        name:'',
        category_ids: '',
        product_type: '',
        score_id: '',
        is_active:'',
      }
    }
  },
  mounted() {
    this.getAllCategories();
    this.getAllScores();
    this.getCurrentUser();
  },
  methods: {
    faqCategory(){
      this.$router.push({
        name: "faq-category",
      });
    },
    createFaq(){
      this.$router.push({
          name: 'faq-create'
        }
      );
    },
    editFaq(faqId){
      this.$router.push({
          name: 'faq-edit',
          params: {
            faqId: faqId
          }
        }
      );
    },
    getAllFaqs(){
      this.loading = true;
      faq
      .paginate(this.search, this.page)
      .then(response => {
        this.faqs = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllCategories(){
      faqCategory
          .getParent()
          .then(response => {
            if(response.data.data.length > 0) {
              this.search.category_ids = response.data.data[0].id;
            }
            this.categories = response.data.data;
            this.getAllFaqs();
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      faq
          .sort(this.faqs)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Faq sorted !!');
            this.getAllFaqs();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllScores(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchFaq(){
      this.getAllFaqs();
    },
    deleteFaq(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            faq
                .delete(id)
                .then((response) => {
                  this.getAllFaqs()
                  this.$snotify.success("FAQ deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    }
  }
}
</script>